import logo from "./img/1.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo} alt="png" />
        <h1 className="covington">COMING SOON</h1>
        {/* <h3 className="covington1">MIRA SPACE</h3>
        <h3 className="covington2">SATELLITE LAB</h3> */}
      </header>
    </div>
  );
}

export default App;
